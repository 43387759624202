import $ from "jquery";
import * as Matter from 'matter-js';
import { Project, Cube } from '../interface';

var Project = <Project>{};

var isMobile = false;
var isTablet = false;

var images:Array<{ src: string, width: number, height: number, orientation: string, folder: string, video: string}> = [];
var activeFolder = <string | any>'';
var flybutton = <HTMLElement | any>null;

var modal = <HTMLElement | any>null;
var modalContent = <HTMLElement | any>null;
var modalImg = <HTMLElement | any>null; 
var modalVideo = <HTMLElement | any>null; 
var modalClose = <HTMLElement | any>null;
var clickCounter = <number>0;
var folders = <any>null;

export function initialize(){
	Project.init();
}

Project.init = async () => {
	// setup browser utilities
	Project.browserUtilities();
	
	// set total clicks and user clicks
	Project.updateCounters();

	// trigger clock / time
	setInterval(Project.updateClock, 1000);
	
	// on directory click show/hide folders.
	$('#directory').on('click', function(){
		$('#hamburger-icon').toggleClass('open');
		$('body').toggleClass('showfolders');
	});

	// on directory folder click make active directory.
	$('body').on('click', '.folders-item', function(){
		
		let folderLabel = $(this).find('.folders-item-label').text();
		activeFolder = folderLabel;
		console.log('activeFolder:', activeFolder);
		$('.active-directory').text(activeFolder);

		// remove cubes in canvas
		engine.world.bodies.forEach((body: any, index: number) => { 
			setTimeout(function(){
				if(index > 3){
					removeCube(body);
				}
			}, 300);
		});

		cubes = [];
		cubeCount = 0;
		flybutton.classList.add("active");	
		
		$('#hamburger-icon').toggleClass('open');
		$('body').toggleClass('showfolders');

	});

	// Add select folder dropdown change listener
	// const folderSelectElement = document.getElementById('folder-select');
	// if(folderSelectElement !== null){
	// 	folderSelectElement.addEventListener('change', function(e) {

	// 		if((e!.target as HTMLSelectElement)!.options){
	// 			activeFolder = (e!.target as HTMLSelectElement)!.options[(e!.target as HTMLSelectElement)!.selectedIndex].text;
	// 			console.log('activeFolder', activeFolder);
	// 		}

	// 		// remove cubes in canvas
	// 		engine.world.bodies.forEach((body: any, index: number) => { 
	// 			setTimeout(function(){
	// 				if(index > 3){
	// 					removeCube(body);
	// 				}
	// 			}, 300);
	// 		});

	// 		cubes = [];
	// 		cubeCount = 0;
	// 		flybutton.classList.add("active");
	// 	});
	// }



	// setup modal elements
	modal = document.getElementById("myModal");
	modalImg = document.getElementById("myModalImg");
	modalVideo = document.getElementById("myModalVideo");
	modalContent = document.getElementById("myModalContent");
	modalClose = document.getElementsByClassName("close")[0];
	modalClose.onclick = function() {
		closeModal();
	};
	window.onclick = function(event) {
		if (event.target == modal) {
			closeModal();
		}
	};
	function closeModal() {
		modal.style.display = "none";
		modalContent.classList.remove('landscape', 'portrait');
		modalVideo.pause();
	}

	// populate folder list.
	await Project.updateFolders();

	// Matter.js module aliases
	var Engine = Matter.Engine,
		Render = Matter.Render,
		World = Matter.World,
		Bodies = Matter.Bodies,
		Body = Matter.Body,
		Mouse = Matter.Mouse,
		MouseConstraint = Matter.MouseConstraint,
		Events = Matter.Events,
		Composite = Matter.Composite,
		Query = Matter.Query,
		Runner = Matter.Runner;

	var clickThreshold = 200; 
	var mouseDownTime: number;            

	// Create an engine
	var engine = Engine.create();

	// Create a renderer
	var render = Render.create({
		element: document.body,
		engine: engine,
		options: {
			width: window.innerWidth,
			height: window.innerHeight,
			background: '#F7F7F7',
			wireframes: false
		}
	});

	// Variables to track cubes and their size
	var cubeCount: number = 0;
	var baseCubeWidth: number, baseCubeHeight: number;
	var cubes: Array<any> = []; // Array to store cubes
	var clickedCube: any = null;

	// Function to calculate cube dimensions
	function calculateCubeDimensions(imageOrientation = 'landscape') {
		baseCubeWidth = window.innerWidth * 0.15;
		baseCubeHeight = baseCubeWidth * 9 / 16; // Maintain 16:9 aspect ratio
		if(imageOrientation == 'portrait'){
			baseCubeHeight = window.innerWidth * 0.15;
			baseCubeWidth = baseCubeHeight * 9 / 16;
		}
		return { width: baseCubeWidth, height: baseCubeHeight };
	}

	// Function to add walls
	function addWalls() {
		// Define walls
		var ground = Bodies.rectangle(window.innerWidth / 2, render.canvas.height, window.innerWidth, 8, { isStatic: true, render: { fillStyle: '#F7F7F7' } }),
			leftWall = Bodies.rectangle(0, render.canvas.height / 2, 60, render.canvas.height, { isStatic: true, render: { fillStyle: '#F7F7F7' } }),
			rightWall = Bodies.rectangle(window.innerWidth, render.canvas.height / 2, 60, render.canvas.height, { isStatic: true, render: { fillStyle: '#F7F7F7' } }),
			topWall = Bodies.rectangle(window.innerWidth / 2, 0, window.innerWidth, 60, { isStatic: true, render: { fillStyle: '#F7F7F7' } });

		// Add walls to the world
		World.add(engine.world, [ground, leftWall, rightWall, topWall]);
	}

	addWalls(); // Initial call to add walls

	// Function to resize and reposition cubes
	function resizeCubes() {
		var newDimensions = calculateCubeDimensions();
		engine.world.bodies.forEach(function(body) {
			if (!body.isStatic) {
				Body.scale(body, newDimensions.width / baseCubeWidth, newDimensions.height / baseCubeHeight);
			}
		});
	}

	function getFolderImages(index = -1){
		// console.log('getFolderImages', index);
		// console.log(' - activeFolder:', activeFolder);
		var folderImages = images.filter(function(image){
			// console.log('imgFilter:', image.folder, activeFolder, image.src);
			return image.folder == activeFolder;
		});
		return index == -1 ? folderImages : folderImages[index];
	}

	function removeCube(cube: Array<any>){
		Composite.remove(engine.world, cube);
	}
	
	// Add button event listener to add cubes
	flybutton = document.getElementById('flyButton');
	flybutton.addEventListener('click', function() {
		flybutton.disabled = true;
		setTimeout(function(){

			// console.log('cubeCount:', cubeCount);
			var folderImages: any = getFolderImages();
			// console.log('folderImages:', folderImages);

			var recImage = folderImages[cubeCount];
			// console.log('recImage:', recImage);

			Project.loadImage (
				recImage.src, 
				function(img: any){
					// console.log('image loading successful.');
					var cubeDimensions = calculateCubeDimensions(recImage.orientation);
					var scaleX = cubeDimensions.width / recImage.width;
					var scaleY = cubeDimensions.height / recImage.height;
					if(recImage.orientation == 'portrait'){
						scaleX = cubeDimensions.width / recImage.width;
						scaleY = cubeDimensions.height / recImage.height;    
					}
					var rect = flybutton.getBoundingClientRect();

					// Create rectangle and set image as texture.
					var cube = <Cube>Bodies.rectangle(
						rect.left + rect.width / 2, 
						rect.top + rect.height / 2, 
						cubeDimensions.width, 
						cubeDimensions.height, 
						{
							frictionAir: 0.01,
							restitution: 0.6,
							render: {
								sprite: {
									texture: recImage.src,
									xScale: scaleX,
									yScale: scaleY    
								}
							}
						}
					);
					cube.orientation = recImage.orientation;
	
					// Apply random force and angular velocity
					var forceMagnitudeX = 0.05 * (Math.random() - 0.5); 
					var forceMagnitudeY = -0.05 * Math.random(); 
					Body.applyForce(cube, cube.position, {
						x: forceMagnitudeX,
						y: forceMagnitudeY
					});
					var randomAngularVelocity = (Math.random() - 0.5) * 0.2;
					Body.setAngularVelocity(cube, randomAngularVelocity);
	
					World.add(engine.world, [cube]);
					cubeCount++;
	
					// Store the cube in the array
					cubes.push(cube); 
	
					// hide click me button if all folder images are shown.
					if(cubeCount == folderImages.length){
						flybutton.classList.remove("active");
					}
	
					// Increase canvas height based on cube count
					if (cubeCount % 20 === 0) {
						render.canvas.height += baseCubeHeight * 0.5;
						addWalls();
					}                    
				},
				function(){
					console.log('Error: image failed to load: '+recImage.src);
				}
			);              

			flybutton.disabled = false;

			clickCounter++;
		}, 150);
	});
	flybutton.classList.add("active");

	// Set up mouse control
	var mouse = Mouse.create(render.canvas);
	var mouseConstraint = MouseConstraint.create(engine, {
		mouse: mouse,
		constraint: {
			stiffness: 0.2,
			render: {
				visible: false
			}
		}
	});

	// Add mouseConstraint to World.
	World.add(engine.world, mouseConstraint);

	// Update mouse down event
	Events.on(mouseConstraint, 'mousedown', function(event) {
		// Record the time when mouse is pressed down
		mouseDownTime = new Date().getTime();

		// Disable the constraint initially
		mouseConstraint.constraint.bodyB = null;

		var mousePosition = { x: event.mouse.position.x, y: event.mouse.position.y };
		var clickedCubes = Query.point(cubes, mousePosition);
		if (clickedCubes.length > 0) {
			// Store the clicked cube, but don't attach it yet
			mouseConstraint.constraint.bodyB = clickedCubes[0];
			clickedCube = clickedCubes[0];
		}
	});

	// Update mouse up event
	Events.on(mouseConstraint, 'mouseup', function(event) {
		// console.log('clickedCube', clickedCube);
		var mouseUpTime = new Date().getTime();
		var duration = mouseUpTime - mouseDownTime;
		// console.log('duration', duration, clickThreshold, mouseUpTime, mouseDownTime);

		if (duration < clickThreshold) {
			// console.log('quick click', mouseConstraint.constraint);
			// If it's a quick click, show an alert
			if (clickedCube) {



				// get cube image texture
				modalImg.src = clickedCube.render.sprite.texture;
				
				let imgArr = modalImg.src.split('/');
				let imgFileName = imgArr[imgArr.length - 1];
				let imageObject = images.filter((image) => {
					return image.src.indexOf(imgFileName) > 0;
				});
				if(imageObject[0].video){

					modalVideo.style.display = 'block';
					modalImg.style.display = 'none';

					var source = modalVideo.getElementsByTagName('source')[0];
					source.src = imageObject[0].video;
					// modalVideo.setAttribute('poster', imageObject[0].src);
					modalVideo.load();
					modalVideo.play();

					modalContent.classList.add('landscape');

					modalContent.style.width = `60%`;

					
				} else {

					modalVideo.style.display = 'none';
					modalImg.style.display = 'block';

					// set modal image orientation
					modalContent.classList.add(clickedCube.orientation);

					// resize modalContent element to fit portrait image.
					if(clickedCube.orientation === 'portrait'){
						const contentHeight = window.innerHeight * 0.8;
						const contentWidth = modalImg.width * (contentHeight / modalImg.height);
						modalContent.style.width = `${contentWidth}px`;

						// console.log('myModalContent', contentWidth, contentHeight);

					}  else {
						modalContent.style.width = `60%`;
					}  
				}                


				modal.style.display = "block";

				// Clear the bodyB as it was just a click
				mouseConstraint.constraint.bodyB = null;
				clickedCube = null;

				Project.saveUserClick();

			}
		} else {
			// If it's a longer hold, the dragging will happen
			// as bodyB is already set in mousedown
		}
	});

	Events.on(mouseConstraint, "mousemove", function(event) {
		var bodiesAtCursor = Query.point(cubes, event.mouse.position);
		
		if(bodiesAtCursor.length){
			render.canvas.style.cursor = 'pointer';
		} else {
			render.canvas.style.cursor = 'default';
		}
	});

	// Run the engine
	Runner.run(engine);

	// Run the renderer
	Render.run(render);

	// Resize listener to update canvas and cubes on window resize
	window.addEventListener('resize', function() {
		render.canvas.width = window.innerWidth;
		render.canvas.height = window.innerHeight;
		resizeCubes();
		addWalls();
	});

};

Project.loadImage = (url, onSuccess, onError) => {
	const img = new Image();
	img.src = url;      
	img.onload = () => {
	  onSuccess(img);
	};
	img.onerror = () => { onError(); };
};

Project.browserUtilities = () => {
	// Mobile Tablet helpers
	isMobile = false;
	isTablet = false;
	var userAgent = navigator.userAgent;
	var $win = $(window);

	// Type device
	if ( (userAgent.indexOf('iPhone') !== -1) || (userAgent.indexOf('Android') !== -1 && userAgent.indexOf('Mobile') !== -1 ))  {
		$('html').addClass('mobile');
		isMobile = true;
	} else if (userAgent.indexOf('iPad') !== -1 || userAgent.indexOf('Android') !== -1) {
		$('html').addClass('tablet');
		isTablet = true;
	} else {
	  $('html').addClass('desktop');
	}

};

Project.updateCounters = async () => {
	const totalClicks = await Project.getTotalClicks();
	$('#totalClicks .header-counter-value').text(totalClicks);
	const userClicks = Project.getUserClicks();
	$('#userClicks .header-counter-value').text(userClicks);
}

Project.getTotalClicks = async () => {
	const response = await fetch(`/redis-totalclicks`);
	const jsonData = await response.json();
	return jsonData.totalclicks;
}

Project.getUserClicks = () => {
	let clicks: any = window.localStorage.getItem('ExperiencesWrapupClicks');
	if(!clicks){
		clicks = 0;
	}
	return clicks;
}

Project.saveUserClick = async () => {
	const response = await fetch(`/redis-updatetotalclicks`);
	const jsonData = await response.json();
	const totalclicks = jsonData.totalclicks;
	// console.log('updatetotalclicks:', jsonData);

	let clicks: any = window.localStorage.getItem('ExperiencesWrapupClicks');
	if(!clicks){
		clicks = 0;
	}
	clicks++;
	window.localStorage.setItem('ExperiencesWrapupClicks', clicks);
	// console.log('saveClick:', clicks);

	// set total clicks and user clicks
	$('#totalClicks .header-counter-value').text(totalclicks);
	$('#userClicks .header-counter-value').text(clicks);
}

Project.updateClock = () => {
    // get a new Date object
    let date: Date = new Date();

	// get hours, minutes and seconds
    let hours: number | string = date.getHours();
    let minutes: number | string = date.getMinutes();
    let seconds: number | string = date.getSeconds();

    // pad with zeroes if necessary
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

	$('.time-hour').text(hours);
	$('.time-min').text(minutes);
	$('.time-sec').text(seconds);
}

Project.updateFolders = async () => {
	const response = await fetch(`/get-gallery-folders`);
	folders = await response.json();
	// console.log('updateFolders', folders);
	
	activeFolder = folders[0];
	$('.active-directory').text(activeFolder);

	$('.folders-items').html('');

	await folders.forEach( async (folder: string) => {
		let html = `
			<div class="folders-item">
				<div class="folders-item-icon">
					<div class="folder">
					<div class="folder__back">
						<div class="paper"></div>
						<div class="paper"></div>
						<div class="paper"></div>
						<div class="folder__front"></div>
						<div class="folder__front right"></div>
					</div>
					</div>
				</div>
				<div class="folders-item-label">${folder}</div>
			</div>
		`;
		$('.folders-items').append(html);
		await Project.updateGallery(folder);
	});

	// get images from folder
	// $('#gallery-items .gallery-item img').each(function(){
	// 	var img = $(this);
	// 	var src = <string>img.attr('src');
	// 	var folder = <string>img.attr('data-folder');
	// 	var width = <number>img.innerWidth();
	// 	var height = <number>img.innerHeight();
	// 	var orientation = width > height ? 'landscape' : 'portrait';
	// 	images.push({
	// 		src: src, 
	// 		width: width, 
	// 		height: height, 
	// 		orientation: orientation, 
	// 		folder: folder
	// 	});
	// });	
	// console.log('folderImages', images);
}

Project.updateGallery = async (folder: string) => {
	// console.log('updateGallery:', folder);
	const response = await fetch(`/get-gallery-files/${folder}`);
	const files = await response.json();
	// console.log(' - files:', files);
	files.forEach( (file: { src: string, folder: string, video: string}) => {
		// let html = `
		// <div class="gallery-item">
		// 	<img src="${file.src}" data-folder="${file.folder}">
		// </div>
		// `;
		// $('#gallery-items').append(html);

		Project.loadImage (
			file.src, 
			function(img: any){
				// console.log('image loaded:', file);
				var orientation = img.width > img.height ? 'landscape' : 'portrait';
				var imageData = {
					src: img.src, 
					width: img.width, 
					height: img.height, 
					orientation: orientation, 
					folder: folder,
					video: '',
				};
				if(file.video){
					imageData.video = file.video;
				}
				images.push(imageData);
			},
			function(){
				console.log('Error: image failed to load: '+file.src);
			}
		);

	});
}
